import React from 'react';
import { Link } from 'react-router-dom';
import { FaCheckCircle, FaHome, FaHotel } from "react-icons/fa";

const Topbar = () => {
    const logoUrl = 'https://res.cloudinary.com/dtxm0dakw/image/upload/v1716088301/venture_inn_logo_new_viz6wj.png';
    return (
        <nav className="navbar navbar-sticky py-4 navbar-glass ">
            <div className="navbar-start">
                <Link to='/' className="navbar-item">
                    <img src={logoUrl} alt="Venture Inn Logo" className='max-h-10 contrast-150 brightness-125 drop-shadow-lg'/>
                </Link>
            </div>
            <div className="navbar-center">
                <Link to='/' className="navbar-item flex items-center space-x-2">
                    <FaHome />
                    <span>
                        Home
                    </span>
                </Link>
                <Link to='/about' className="navbar-item flex items-center space-x-2">
                    <FaHotel />
                    <span>
                        About
                    </span>
                </Link>
                <Link to='/bookingPage' className="navbar-item flex items-center space-x-2">
                    <FaCheckCircle />
                    <span>
                        Booking
                    </span>
                </Link>
            </div>
            <div className="navbar-end">
                <Link to='/contact'>
                    <button className="btn btn-outline border-blue-400 text-blue-400">
                        Contact
                    </button>
                </Link>
            </div>
        </nav>
    )
}

export default Topbar
