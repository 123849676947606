import PageNavigation from './navigations';
import Navbar from './components/navbar';
import Footer from './components/footer';
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <>
      <Navbar />
      <PageNavigation />
      <Footer />
      <Analytics/>
    </>
  );
}

export default App;
