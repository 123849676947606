// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";



// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAPJ1pGrOMGLy0g941uxzctIOxxXqTBGYU",
    authDomain: "ventureinn-29dc8.firebaseapp.com",
    projectId: "ventureinn-29dc8",
    storageBucket: "ventureinn-29dc8.appspot.com",
    messagingSenderId: "59858125895",
    appId: "1:59858125895:web:660753eea827e262259d62",
    measurementId: "G-ZD7HGSTBM2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
