import React from 'react';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import useSmoothScroll from '../../hooks/useSmoothScroll';

const AboutPage = () => {
  useSmoothScroll()

  const backgroundImage = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715221/tbmwaxh1id1sbnwhqe6e.jpg';
  const image5 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715222/kvvowvg9ufdkq9ghvknb.jpg';
  const image8 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715223/p0qryz1mc6rr3kph3r3k.jpg';
  const image11 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715224/fv7fbdwugsw156nwugmr.jpg';
  const image12 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715224/ufd81thiafw0bejclj5o.jpg';
  const image13 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715224/bvmxrjuxqbayrcg716av.jpg';

  return (
    <div>
      {/* Image with text */}
      <div className="relative">
        <img loading="lazy" src={backgroundImage} alt="About Us" className="w-full h-64" />
        <div className='top-1/4 absolute'>
          <div className="text-5xl p-8 m-5 font-extrabold text-white text-bold  ">
            About Us
          </div>
        </div>
      </div>
      {/* Venture Inn section */}
      <div className=''>
        <div className="bg-black p-8  gap-2">
          <div>
            <h2 className="text-3xl text-center font-bold mb-4 lg:text-7xl text-blue-500">Venture Inn</h2>
            <p className="text-white text-center md:text-md lg:text-3xl">
              Venture-In offers a range of rooms to suit every budget. Enjoy our <div className='text-bold text-blue-500'>Super Deluxe AC rooms</div> at just Rs. 1500, non-AC at Rs. 1200, and <div className='text-bold text-blue-500'>Deluxe AC rooms</div> at Rs. 1200, and non-AC at Rs. 900. Experience comfort and quality at affordable prices
            </p>
          </div>
        </div>

        <div className="mx-auto max-w-5xl ">
          <h2 className="p-5 justify-center items-center text-2xl text-blue-500 font-bold text-center mt-8 mb-4">Super Deluxe and Deluxe Rooms</h2>
          <Swiper
            spaceBetween={7}
            slidesPerView={3}
            centeredSlides={true}
            autoplay={{ delay: 1000 }}
            loop={true}
            className="mb-4"
            breakpoints={{
              640: {
                slidesPerView: 1,   //// when window width is >= 640px
              },
              768: {

                slidesPerView: 2,   //  when window width is >= 768px 
              },
              1024: {
                slidesPerView: 3,   // when window width is >= 1024px
              },
            }}>
            <SwiperSlide>
              <img loading="lazy" src={image5} alt="Venture inn" className="rounded-lg w-full" />
            </SwiperSlide>
            <SwiperSlide>
              <img loading="lazy" src={image8} alt="Venture inn" className="rounded-lg w-full" />
            </SwiperSlide>
            <SwiperSlide>
              <img loading="lazy" src={image11} alt="Venture inn" className="rounded-lg w-full" />
            </SwiperSlide>
            <SwiperSlide>
              <img loading="lazy" src={image12} alt="Venture inn" className="rounded-lg w-full" />
            </SwiperSlide>
            <SwiperSlide>
              <img loading="lazy" src={image13} alt="Venture inn" className="rounded-lg w-full" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};
export default AboutPage;
