import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="bg-blue-950 rounded-tr-xl rounded-tl-xl text-white py-12">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-4">
          {/* First Column */}
          <div>
            <h2 className="text-3xl font-bold text-purple-400">Venture In</h2>
            <p className="mt-2">Choose us for a seamless blend of comfort and convenience. Experience exceptional service and amenities tailored to exceed your expectations</p>
          </div>
          {/* Second Column */}
          <div>
            <h2 className="text-xl font-bold text-purple-400">Location</h2>
            <p className="mt-2">Near PharmEasy Medical Store, Lane Number 2, Vijaya Vihar, Lingipur, Bhubaneswar, Odisha 751002</p>
          </div>
          {/* Third Column */}
          <div>
            <h2 className="text-xl font-bold text-purple-400">Contact Us</h2>
            <p className="flex items-center space-x-2"><FaPhoneAlt /> <span>+91 9040985900</span> </p>
            <p className="flex items-center space-x-2"><MdEmail /> <span>ventureinnstay@gmail.com</span> </p>
            
          </div>
        </div>
      </footer>
      <div className="py-4 bg-[#071B47] text-center">
        Develop And Design by <Link to='https://brandladder.co.in/' className="link link-primary" >Brandladder Pvt Ltd</Link>
      </div>
    </>
  );
}
export default Footer;
