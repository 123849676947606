import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import app from '../../../config/firebaseConfig'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import Bookings from "../pages/bookings";

function AdminLoginPage() {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [loggedIn, setLoggedIn] = useState(false); // State to track authentication status
  const auth = getAuth(app); 

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, formData.email, formData.password);
      toast.success("Login successful!");
      setLoggedIn(true); // Set authentication status to true
    } catch (error) {
      // console.error("Login Error: ", error);
      toast.error("Login failed. Please check your credentials.");
    }
  };

  if (loggedIn) {
    return <Bookings />;
  }

  return (
    <div className="min-h-screen bg-cover bg-blue-900 h-full bg-center " style={{ backgroundImage: `url(https://res.cloudinary.com/dooggwrub/image/upload/v1714715223/zmgym3epjaqat4fhej41.png)` }}>
      <div className="flex justify-center items-center h-full">
        <div className="mt-14 bg-blue-950 bg-opacity-80 rounded-lg shadow-md shadow-sky-700 max-w-md text-center items-center p-7">
          <form className="text-white" onSubmit={handleLogin}>
            <h1 className="text-4xl text-center text-stone-100 font-bold p-6">Admin Login</h1>
            <div className="grid grid-cols-1 gap-6">
              <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleInputChange} className="input-field  bg-black text-white text-bold text-lg rounded-full p-3" />
              <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleInputChange} className="input-field  bg-black text-white text-bold text-lg rounded-full p-3" />
              <button type="submit" className="btn bg-blue-900 text-white btn-outline-primary font-bold text-bold text-lg py-2 px-4 rounded-full">Login</button>
            </div>
          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}

export default AdminLoginPage;
