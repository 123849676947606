import Home from '../pages';
// import Blogs from '../pages/blogs';
import About from '../pages/about';
import BookingPage from '../pages/bookingPage';
import Contact from '../pages/Contact';
import AdminLoginPage from '../pages/admin/auth';
export const navigations = [
    {
        path: '/',
        element: <Home />,
    },
    {
        path: '/bookingPage',
        element: <BookingPage />,
    },
    // {
    //     path: '/blogs/:blogID',
    //     element: < Blogs />,
    // },
    {
        path: '/about',
        element: <About />,
    },
    {
        path: '/contact',
        element: <Contact />,
    },
    {
        path: '/admin-login',
        element: <AdminLoginPage />,
    },
]