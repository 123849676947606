import React, { Suspense, lazy } from 'react';
import useSmoothScroll from '../../hooks/useSmoothScroll';
import { CiMail } from "react-icons/ci";
const MapLocation = lazy(() => import('./LocationInMap'));
const Contact = () => {
    useSmoothScroll()
    return (
        <div className="bg-cover bg-center min-h-screen bg-gray-900" >
            <div className="flex flex-col gap-4 justify-center items-center min-h-screen p-4"> {/* Increased height */}
                <div className="text-white font-bold text-center mb-1 md:mb-10"> {/* Adjusted margin class */}
                    <h1 className="text-xl md:text-5xl font-serif">Uncover our location for an exceptional exquisite comfort!</h1>
                </div>
                <section className='grid grid-cols-1 md:grid-cols-2 gap-4 mx-auto'>
                    <div className="w-full h-86 md:h-full">
                        <Suspense fallback={<div>Loading map...</div>}>
                            <MapLocation />
                        </Suspense>
                    </div>
                    <div className="w-full h-66 md:h-full bg-black rounded-md opacity-75"> {/* Increased height */}
                        {/* Contact Information */}
                        <div className="mx-auto p-4">
                            <div className='space-y-4 text-white'>
                                <h2 className="text-xl md:text-3xl text-yellow-300 font-semibold">Experience exquisite comfort and luxury in our meticulously furnished hotel rooms</h2>
                                <p className="text-lg md:text-xl"><span className='font-bold text-blue-500'>Address:</span> Near PharmEasy Medical Store, Lane Number 2, Vijaya Vihar, Lingipur, Bhubaneswar, Odisha 751002 </p>
                                <p className="text-lg md:text-xl flex"><span className='font-bold text-blue-500'>Email id:</span><CiMail className='my-auto mr-2' /> ventureinnstay@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
export default Contact;


