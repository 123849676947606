import React from "react";

function Booking() {

  const img1 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715221/x55wepezlnlpq7vuljc4.jpg';
  const img2 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715224/fxa0gc0atl1yzmoeh6en.jpg';
  const img3 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715221/lynj021xpaej9js9okjg.jpg';

  return (
    <div className=" bg-black mx-auto p-10">
      <h1 className="text-4xl font-bold text-blue-500 text-center p-8 mb-6">Why Choose Us</h1>
      <p className="text-lg text-center text-white mb-8">Choose us for a seamless blend of comfort and convenience. Experience exceptional service and amenities tailored to exceed your expectations</p>
      <div className="grid grid-cols-1 mx-10 sm:grid-cols-3 gap-11">
        <div className="border-blue-500 border-2 p-4 rounded-lg text-center">
          <img loading="lazy" src={img1} alt="Venture inn" className="w-16 h-16 mx-auto mb-3" />
          <h2 className=" text-blue-500 text-lg font-bold mb-2">Easy Bookings</h2>
          <p className=" text-white text-base">Effortless bookings for your perfect stay. Reserve your room quickly and easily online</p>
        </div>
        <div className="border-blue-500 border-2 p-4 rounded-lg text-center">
          <img loading="lazy" src={img2} alt="Venture inn" className="w-16 h-16 mx-auto mb-3" />
          <h2 className=" text-blue-500 text-lg font-bold mb-1">Responsibility</h2>
          <p className="text-white text-base">we prioritize guest safety with rigorous cleanliness standards. Our attentive staff ensures a comfortable stay</p>
        </div>
        <div className="border-blue-500 border-2 p-4 rounded-lg text-center">
          <img loading="lazy" src={img3} alt="Venture inn" className="w-16 h-16 mx-auto pb-3" />
          <h2 className="text-blue-500 text-lg font-bold mb-2">Friendly</h2>
          <p className=" text-white text-base">fosters a warm and inviting atmosphere where guests feel at home.</p>
        </div>
      </div>
    </div>
  );
}
export default Booking;
