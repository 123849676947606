import React from 'react';
import Topbar from './topbar';
import Sidebar from './sidebar';

const NavBar = () => {

  return (
    <header className='navbar-sticky'>
      <div className='hidden lg:block'>
        <Topbar />
      </div>
      <div className='block lg:hidden'>
        <Sidebar />
      </div>
    </header>
  )
}

export default NavBar;  