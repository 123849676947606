import React, { useState, useEffect } from "react";
import { getFirestore, collection, doc, setDoc, getDocs} from 'firebase/firestore';
import app from '../../config/firebaseConfig'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BookingPage() {
  const backgroundImage = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715223/zmgym3epjaqat4fhej41.png';

  const [selectedRoom, setSelectedRoom] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    checkInDate: '',
    checkOutDate: '',
    numberOfGuests: '',
    contact: ''
  });

  const roomOptions = [
    "Super Deluxe Room(AC)",
    "Super Deluxe Room(Non-AC)",
    "Deluxe Room(AC)",
    "Deluxe Room(Non-AC)"
  ];

  const [error, setError] = useState(null); // State to hold error

  // Initialize Firestore
  const firestore = getFirestore(app); 

  useEffect(() => {
    if (!app) {
      setError("Firebase is not initialized. Please check your Firebase configuration.");
    }
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const today = new Date();
    const bookingDate = `${today.getDate()}${today.toLocaleString('default', { month: 'short' })}${today.getFullYear()}`; 
    const bookingData = {
      checkInDate: formData.checkInDate,
      checkOutDate: formData.checkOutDate,
      numberOfGuests: formData.numberOfGuests,
      roomType: selectedRoom,
      name: formData.name,
      contact: formData.contact
    };
    try {
      const firestore = getFirestore(app); // Assuming 'app' is your Firebase app instance
      const dateCollectionRef = collection(firestore, bookingDate);
  
      // Get all the documents in the date collection to determine the new document name
      const dateCollectionSnapshot = await getDocs(dateCollectionRef);
      const newDocumentName = `${dateCollectionSnapshot.size + 1}`; // Incrementing the document name
  
      // Set the new document with booking data under the date collection
      const newDocumentRef = doc(dateCollectionRef, newDocumentName);
      await setDoc(newDocumentRef, bookingData);
      toast.success("Booking Confirmation Requested, expect a call from our executive within 24hrs to confirm your booking.");
    } catch (error) {
      toast.error("Booking Failed, please call +91 9040985900 for more information");
      setError("Booking Failed, please call +91 9040985900 for more information");
    }
  };
  

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="min-h-screen bg-cover bg-blue-900 opacity-97 h-full bg-center " style={{ backgroundImage: `url(${backgroundImage})` }}>
      <ToastContainer />
      <div className="absolute inset-0 backdrop-filter backdrop-blur-[2px]"></div>
      <div className="flex justify-center items-center  h-full">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10  p-8 rounded-lg z-20">
          <div className="items-center mt-14 justify-center">
            <div className="md:text-left justify-center mt-12  items-center text-white">
              <h1 className="text-5xl text-center mt-7 justify-center font-bold">A Memorable Experience</h1>
              <p className="text-3xl text-center p-5 ">Indulge in a memorable experience with us, where every moment is crafted to leave a lasting impression. From luxurious accommodations to personalized service, your stay will be nothing short of extraordinary</p>
            </div>
          </div>
          <div className="  mt-14 bg-blue-950  bg-opacity-60 rounded-lg md:text-right max-w-5xl text-center items-center  p-7">
            <form className="text-white" onSubmit={handleFormSubmit}>
              <h1 className="text-4xl text-center text-white font-bold p-6">Book a Room</h1>
              <div className="grid grid-cols-1 gap-6">
                <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleInputChange} className="input-field text-bold text-lg bg-black text-white rounded-full p-3" />
                <input type="date" name="checkInDate" value={formData.checkInDate} onChange={handleInputChange} className="input-field  bg-black text-white text-bold w-auto rounded-full p-3" />
                <input type="date" name="checkOutDate" value={formData.checkOutDate} onChange={handleInputChange} className=" bg-black text-white input-field text-bold w-auto rounded-full p-3" />
                <select value={selectedRoom} onChange={(e) => setSelectedRoom(e.target.value)} className="input-field text-bold  bg-black text-white rounded-full p-3" style={{ width: '100%' }}>
                  <option value="" disabled>Select Room Type</option>
                  {roomOptions.map((room, index) => (
                    <option key={index} value={room}>{room}</option>
                  ))}
                </select>
                <input type="number" name="numberOfGuests" placeholder="No of Guests" value={formData.numberOfGuests} onChange={handleInputChange} className=" bg-black text-white input-field text-bold rounded-full p-3" />
                <input type="text" name="contact" placeholder="Contact" value={formData.contact} onChange={handleInputChange} className="input-field  bg-black text-white text-bold rounded-full p-3" />
                <button type="submit" className="btn bg-blue-900 text-white btn-outline-primary font-bold text-bold text-lg py-2 px-4 rounded-full">Book Now</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingPage;
