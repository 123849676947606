import React from "react";
import FeatureCard from "./FeatureCard";

function FacilityPage() {

  const img1 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715225/fzebxfqiu9curpwu9pv1.jpg';
  const img2 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715224/hkmjgg5dmnr5ks6unyva.jpg';
  const img3 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715225/skiypbo3rg4chtkz1voi.jpg';
  const img4 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715223/k8elymkutbtxqihqmtom.jpg';
  const img5 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715224/woxmd9vfxkoy6ofrknmy.jpg';
  const img6 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715221/r93zqwn5slbd5elqablx.jpg';

  return (
    <div className="bg-black min-h-screen pt-12">
      <div className="pt-14 container mx-auto p-6">
        <h1 className="text-4xl font-bold  text-blue-500 text-center mb-12">Most Popular Facilities</h1>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-6 ">
          <FeatureCard img={img1} title="Free WIFI" />
          <FeatureCard img={img2} title="Free Parking" />
          <FeatureCard img={img3} title="Television" />
          <FeatureCard img={img4} title="Gyser" />
          <FeatureCard img={img5} title="Power BackUp" />
          <FeatureCard img={img6} title="Air Condition" />
        </div>
      </div>
    </div>
  );
}
export default FacilityPage;
