import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

function ImageSlider() {

  const image1 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715222/rgdddv9ldn55k3jczotv.jpg';
  const image2 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715222/j1uub3udxujr2vqiolnj.jpg';
  const image3 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715223/zslfxszniy3f0tsbgvhi.jpg';
  const image4 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715223/zfepc4xsom3nuj3meskm.jpg';
  const image5 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715222/kvvowvg9ufdkq9ghvknb.jpg';
  const image6 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715222/jmmvoslc6ogkyj0kclxs.jpg';
  const image7 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715222/luaowdsnaocy4ffskrb4.jpg';
  const image8 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715223/p0qryz1mc6rr3kph3r3k.jpg';
  const image9 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715223/km6okeuutyjkbrxh2ekd.jpg';
  const image10 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715224/ic2gzbqpn9a85bh7jui3.jpg';
  const image11 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715224/fv7fbdwugsw156nwugmr.jpg';
  const image12 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715224/ufd81thiafw0bejclj5o.jpg';
  const image13 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715224/bvmxrjuxqbayrcg716av.jpg';

  return (
    <div className="min-h-screen overflow-hidden bg-cover bg-black flex justify-center items-center ">
      <div className='grid grid-cols-1 mx-auto'>
        <div >
          <div className="mx-auto max-w-4xl">
            <h1 className="text-4xl text-white text-center font-bold mb-4 top-0">Our Venture Inn Images</h1>
            <h2 className=" justify-center items-center text-2xl text-blue-500 font-bold p-4 text-center mb-4">Super Deluxe and Deluxe Rooms</h2>
            <Swiper
              spaceBetween={7}
              slidesPerView={3}
              centeredSlides={true}
              autoplay={{ delay: 1000 }}
              loop={true}
              className="mb-4"
              breakpoints={{
                640: {
                  slidesPerView: 1,   // when window width is >= 640px
                },
                768: {
                  slidesPerView: 2,     // when window width is >= 768px
                },
                1024: {
                  slidesPerView: 3,     // when window width is >= 1024px
                },
              }}>
              <SwiperSlide>
                <img loading="lazy" src={image1} alt="Venture inn" className="rounded-lg w-full" />
              </SwiperSlide>
              <SwiperSlide>
                <img loading="lazy" src={image2} alt="Venture inn" className="rounded-lg w-full" />
              </SwiperSlide>
              <SwiperSlide>
                <img loading="lazy" src={image4} alt="Venture inn" className="rounded-lg w-full" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={image6} alt="Venture inn" className="rounded-lg w-full" />
              </SwiperSlide>
              <SwiperSlide>
                <img loading="lazy" src={image3} alt="Venture inn" className="rounded-lg w-full" />
              </SwiperSlide>
              <SwiperSlide>
                <img loading="lazy" src={image7} alt="Venture inn" className="rounded-lg w-full" />
              </SwiperSlide>
              <SwiperSlide>
                <img loading="lazy" src={image9} alt="Venture inn" className="rounded-lg w-full" />
              </SwiperSlide>
              <SwiperSlide>
                <img loading="lazy" src={image10} alt="Venture inn" className="rounded-lg w-full" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div>
          <div className="mx-auto max-w-4xl ">
            <h2 className="justify-center items-center text-2xl text-blue-500 font-bold p-4 text-center mb-4">Super Deluxe and Deluxe Rooms</h2>
            <Swiper
              spaceBetween={7}
              slidesPerView={3}
              centeredSlides={true}
              autoplay={{ delay: 1000 }}
              loop={true}
              className="mb-4"
              breakpoints={{
                640: {
                  slidesPerView: 1,   //// when window width is >= 640px
                },
                768: {

                  slidesPerView: 2,   //  when window width is >= 768px 
                },
                1024: {
                  slidesPerView: 3,   // when window width is >= 1024px
                },
              }}>
              <SwiperSlide>
                <img loading="lazy" src={image5} alt="Venture inn" className="rounded-lg w-full" />
              </SwiperSlide>
              <SwiperSlide>
                <img loading="lazy" src={image8} alt="Venture inn" className="rounded-lg w-full" />
              </SwiperSlide>
              <SwiperSlide>
                <img loading="lazy" src={image11} alt="Venture inn" className="rounded-lg w-full" />
              </SwiperSlide>
              <SwiperSlide>
                <img loading="lazy" src={image12} alt="Venture inn" className="rounded-lg w-full" />
              </SwiperSlide>
              <SwiperSlide>
                <img loading="lazy" src={image13} alt="Venture inn" className="rounded-lg w-full" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ImageSlider;
