import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import app from '../../../config/firebaseConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BookingPage() {
  const backgroundImage = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715223/zmgym3epjaqat4fhej41.png';

  const formatDate = (date) => {
    return `${date.getDate()}${date.toLocaleString('default', { month: 'short' })}${date.getFullYear()}`; 
  };

  const formDate = (date) => {
    return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
  };

  const [selectedDate, setSelectedDate] = useState(formatDate(new Date()));
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const firestore = getFirestore(app);

  useEffect(() => {
    if (!app) {
      setError("Firebase is not initialized. Please check your Firebase configuration.");
      return;
    }
    fetchBookings(selectedDate);
  }, [selectedDate]); // Updated to trigger fetchBookings when selectedDate changes

  const fetchBookings = async (formattedDate) => {
    try {
      // console.log('Fetching bookings for date:', formattedDate);
      const dateCollectionRef = collection(firestore, formattedDate);
      // console.log('Collection Reference:', dateCollectionRef); 
      const querySnapshot = await getDocs(dateCollectionRef);
      // console.log('Query Snapshot:', querySnapshot); 
      const bookingsData = querySnapshot.docs.map(doc => doc.data());
      // console.log('Bookings Data:', bookingsData); 
      setBookings(bookingsData);
      setLoading(false);
    } catch (error) {
      // console.error("Error fetching bookings: ", error);
      toast.error('Error fetching bookings. Please try again later.')
      setError("Error fetching bookings. Please try again later.");
    }
  };

  const handleDateChange = (e) => {
    setSelectedDate(formatDate(new Date(e.target.value)));
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="min-h-screen bg-cover bg-blue-900 opacity-97 h-full bg-center " style={{ backgroundImage: `url(${backgroundImage})` }}>
      <ToastContainer />
      <div className="flex justify-center items-center  h-full">
        <div className="mt-14 bg-blue-950 shadow-md bg-opacity-80 rounded-lg max-w-3xl text-center items-center p-7">
          <h1 className="text-4xl text-center text-white font-bold p-6">Bookings</h1>
          <div className="grid grid-cols-1 gap-6">
            <input type="date" value={formDate.date} onChange={handleDateChange} className="input-field text-bold w-auto bg-black text-white rounded-full p-3" />
            <button onClick={() => fetchBookings(selectedDate)} className="btn bg-blue-900 btn-outline-primary font-bold text-bold text-lg py-2 px-4 rounded-full text-white">Show Bookings</button>
            {loading ? (
              <div className="text-white">Loading...</div>
            ) : (
              <div>
               {bookings.length === 0 ? (
                <div className="text-white bg-red-800 font-bold">No bookings found for the selected date.</div>
              ) : (
                <div className="text-white bg-opacity-80 ">
                  <h2 className="text-2xl font-bold">Bookings for {selectedDate}</h2>
                  <div className="w- overflow-x-auto overflow-y-auto max-h-44">
                    <table className="w-full md:w-auto border-collapse border border-white">
                      <thead>
                        <tr>
                          <th className="border-2 border-red-800  bg-violet-950 font-extrabold p-2">Booking ID</th>
                          <th className="border-2 border-red-800  bg-violet-950 font-extrabold p-2">Name</th>
                          <th className="border-2 border-red-800  bg-violet-950 font-extrabold p-2">Contact</th>
                          <th className="border-2 border-red-800  bg-violet-950 font-extrabold p-2">No of Guests</th>
                          <th className="border-2 border-red-800  bg-violet-950 font-extrabold p-2">Check-In Date</th>
                          <th className="border-2 border-red-800  bg-violet-950 font-extrabold p-2">Checkout Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookings.map((booking, index) => (
                          <tr key={index}>
                            <td className="border-2 border-green-800 bg-blue-950 font-bold p-2">{index + 1}</td>
                            <td className="border-2 border-green-800  bg-blue-950 font-bold p-2">{booking.name}</td>
                            <td className="border-2 border-green-800  bg-blue-950 font-bold p-2">{booking.contact}</td>
                            <td className="border-2 border-green-800  bg-blue-950 font-bold p-2">{booking.numberOfGuests}</td>
                            <td className="border-2 border-green-800  bg-blue-950 font-bold p-2">{booking.checkInDate}</td> 
                            <td className="border-2  border-green-800  bg-blue-950 font-bold p-2">{booking.checkOutDate}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);
}


export default BookingPage;
