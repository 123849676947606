import React from 'react'

const Rooms = ({ img, title, subtitle }) => {
    return (
        <div className="rounded-lg shadow-xl border border-blue-400 bg-inherit grid grid-row-2 gap-4">
            <div className="h-80">
                <img loading="lazy" src={img} alt="venture in" className="w-full h-full" />
            </div>
            <div className="card-body">
                <h2 className="card-header text-blue-500">{title}</h2>
                <p className="text-content2">{subtitle}</p>
            </div>
        </div>
    )
}

export default Rooms
