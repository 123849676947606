import React from 'react'

const FeatureCard = ({ img, title }) => {
    return (
        <div className="rounded-full text-white p-6 border-2  border-blue-500">
            <img loading="lazy" src={img} alt="Venture in" className="w-16 h-16 rounded-full mx-auto" />
            <h2 className="text-base font-bold mt-2 text-center">{title}</h2>
        </div>
    )
}

export default FeatureCard
