import React from "react";
function PlanPage() {
  const backgroundImg = "https://res.cloudinary.com/dooggwrub/image/upload/v1714715223/amlohi4ookz5poqpfzw8.png";
  return (
    <div className="relative bg-cover bg-blue-950 bg-center h-screen" style={{ backgroundImage: `url(${backgroundImg})` }}>
      <div className="absolute inset-0 backdrop-filter backdrop-blur-[1.4px]"></div>
      <section className="h-screen w-4/5 mx-auto flex flex-col items-center justify-center ">
        <span className="w-full hidden md:block text-center text-gray-300 z-20">
          Choose us for a seamless blend of comfort and convenience. Experience exceptional service and amenities tailored to exceed your expectations</span>
        <h1 className="text-4xl text-center lg:text-7xl z-20 font-bold">
          Our Hotel Serves the best facility in <span className="text-white">Bhubaneswar !!</span>
        </h1>
        <span className="w-full block md:hidden text-center text-gray-300 z-20">
          Choose us for a seamless blend of comfort and convenience. Experience exceptional service and amenities tailored to exceed your expectations
        </span>
      </section>
    </div>
  )
}

export default PlanPage;



