import React, { useRef, useEffect } from 'react';
import useSmoothScroll from '../hooks/useSmoothScroll';
import BlankScreen from '../components/blankscreen';
import Plans from '../cards/plans';
import Booking from '../cards/booking';
import PlansAndRooms from '../cards/plansAndRooms';
import FacilityPage from '../cards/Facilities';
import ImageSlider from './gallery';

const Home = () => {
  useSmoothScroll()
  const plansRef = useRef(null);

  useEffect(() => {
    const plansElement = plansRef.current;

    const handleScroll = () => {
      if (plansElement) {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const plansTop = plansElement.offsetTop;
        const plansHeight = plansElement.clientHeight;

        if (scrollTop > plansTop) {
          plansElement.style.position = 'fixed';
          plansElement.style.top = 0;
        } else {
          plansElement.style.position = 'static';
        }

        const scrollPercentage = (scrollTop - plansTop + 100) / (plansHeight);
        plansElement.style.opacity = 1 - scrollPercentage;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <main>
      <div ref={plansRef} >
        <Plans />
      </div>
      <BlankScreen />
      <BlankScreen />
      <Booking />
      <PlansAndRooms />
      <FacilityPage />
      <ImageSlider />
    </main>
  );
};

export default Home;
