import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { navigations } from './navigations';

const PageNavigation = () => {
    return (
        <Routes>
            {
                navigations?.map((data, index) => {
                    return (
                        <Route key={index} path={data.path} element={data.element} />
                    )
                })
            }
        </Routes>
    )
}

export default PageNavigation
