import React from 'react';
import { Link } from 'react-router-dom';

import { FaCheckCircle, FaHome, FaHotel } from "react-icons/fa";
import { IoCallSharp } from "react-icons/io5";
import { IoMdMenu } from "react-icons/io";

const Sidebar = () => {

    const logoUrl = 'https://res.cloudinary.com/dtxm0dakw/image/upload/v1716088301/venture_inn_logo_new_viz6wj.png';
    return (
        <div className="navbar rounded-lg bg-gray-950">
            <div className="navbar-start">
                <Link to='/' className="navbar-item">
                    <img src={logoUrl} alt="Venture Inn Logo" className='max-h-10 contrast-200 brightness-200'/>
                </Link>
            </div>
            <div className="navbar-end">
                <div className="avatar avatar-ring avatar-md">
                    <div className="dropdown-container">
                        <div className="dropdown">
                            <label className="btn btn-ghost flex cursor-pointer px-0" tabIndex="0">
                                <IoMdMenu />
                            </label>
                            <div className="dropdown-menu dropdown-menu-bottom-left">
                                <Link to='/' className="dropdown-item text-sm flex flex-row items-center space-x-4">
                                    <FaHome />
                                    <span>Home</span>
                                </Link>
                                <Link to='/about' tabIndex="-1" className="dropdown-item text-sm flex flex-row items-center space-x-4">
                                    <FaHotel />
                                    <span>About Us</span>
                                </Link>
                                <Link to='/contact' tabIndex="-1" className="dropdown-item text-sm flex flex-row items-center space-x-4">
                                    <IoCallSharp />
                                    <span>Contact Us</span>
                                </Link>
                                <Link to='/bookingPage' tabIndex="-1" className="dropdown-item text-sm flex flex-row items-center space-x-4">
                                    <FaCheckCircle />
                                    <span>Booking</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
