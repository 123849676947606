import React from "react";
import Rooms from '../cards/Room';

function PlansAndRooms() {

  const img1 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715222/kvvowvg9ufdkq9ghvknb.jpg';
  const img2 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715223/zfepc4xsom3nuj3meskm.jpg';
  const img3 = 'https://res.cloudinary.com/dooggwrub/image/upload/v1714715223/p0qryz1mc6rr3kph3r3k.jpg';

  return (
    <div className="bg-black py-12">
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold text-blue-500 text-center mb-12">Plans and Rooms</h1>
        <div className=" grid grid-cols-1 md:grid-cols-3 gap-4 px-4 md:px-10">
          <Rooms
            img={img1}
            title='Super Deluxe Rooms - 1'
            subtitle='AC rooms available at Rs 1500 and Our Non-Ac rooms available at Rs 1200' />
          <Rooms
            img={img2}
            title='Super Deluxe Rooms - 2'
            subtitle='AC Rooms available at Rs 1500 and Our Non-AC Rooms at Rs 1200' />
          <Rooms
            img={img3}
            title=' Deluxe Rooms'
            subtitle='AC Rooms available at Rs 1200 and Non-AC Rooms at Rs 900' />
        </div>
      </div>
    </div>
  );
}

export default PlansAndRooms;
